import React from 'react';
import { BannerMonthlyPromo } from 'views/offer/banner-monthly-promo';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { graphql, useStaticQuery } from 'gatsby';
import { PATHS } from 'constants/paths';
import { treatmentsHubs } from 'constants/treatment-hubs';

import { TreatmentsList } from '../components/treatments-list';
import styled from 'styled-components';
const SImageWrapper = styled(ImageWrapper)`
  max-width: 1200px;
`;

export const useStimulantsContent = () => {

  const images = useStaticQuery(graphql`
      query {
      one: file(relativePath: { eq: "pluryal.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      two: file(relativePath: { eq: "sculptra3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      three: file(relativePath: { eq: "profhilo2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
      four: file(relativePath: { eq: "ig-oczy-3.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return {
    title: 'Zabiegi z Użyciem Stymulatorów Tkankowych',
    sections: [
      {
        type: 'section',
        subtitle: 'Czym jest Stymulator Tkankowy?',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>Stymulator tkankowy</b> to specjalistyczna substancja lub preparat medyczny stosowany w celu pobudzenia naturalnych procesów regeneracyjnych skóry. Działa poprzez stymulację produkcji kolagenu i elastyny, które są kluczowe dla utrzymania elastyczności i jędrności skóry. Stymulatory tkankowe są używane w medycynie estetycznej do poprawy jakości skóry, zwiększenia jej nawilżenia oraz redukcji oznak starzenia, co przyczynia się do <b>odmłodzonego i zdrowego wyglądu cery</b>.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Zastosowanie Stymulatora Tkankowego',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Stymulatory tkankowe znajdują zastosowanie w różnych terapiach mających na celu poprawę kondycji skóry. Są efektywne w leczeniu takich problemów jak utrata jędrności, głębokie zmarszczki, blizny, a także w ogólnej rewitalizacji i odmładzaniu skóry. Ich unikalna zdolność do regeneracji tkanki sprawia, że są często wybierane do intensywnych zabiegów rewitalizujących oraz w procedurach mających na celu długotrwałą poprawę wyglądu skóry.',
          },
          { type: 'node', content: <SImageWrapper>
              <Image
                src={images.three.childImageSharp}
                alt="stymulatory tkankowe ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.two.childImageSharp}
                alt="biostymualtory ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.one.childImageSharp}
                alt="stymulatory ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.four.childImageSharp}
                alt="stymulatory ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Rodzaje Zabiegów z Wykorzystaniem Stymulatorów Tkankowych',
        nodes: [
          {
            type: 'node',
            content: (
              <TreatmentsList
                offers={treatmentsHubs.tissueStimulator.treatments}
              />
            ),
          },
          {
            type: 'paragraph',
            content:
              'W Klinice Medycyny Estetycznej IDEALE oferujemy szeroki zakres stymulatorów tkankowych, które są skuteczne w różnorodnych zabiegach regeneracyjnych i odmładzających skórę. Każdy z tych stymulatorów charakteryzuje się unikalnymi właściwościami i jest dostosowany do specyficznych potrzeb skóry naszych pacjentów.',
          },
          {
            type: 'paragraph',
            content:
              'Stymulatory oparte na nukleotydach są idealne dla osób poszukujących głębokiej regeneracji i poprawy tekstury skóry. Inne, zawierające zaawansowane formuły hybrydowe, intensywnie nawilżają i poprawiają elastyczność skóry. Z kolei stymulatory zawierające aminokwasy skutecznie rewitalizują i regenerują skórę, będąc doskonałym rozwiązaniem dla osób potrzebujących intensywnej kuracji odmładzającej.',
          },
          {
            type: 'paragraph',
            content:
              'Dodatkowo, w naszej ofercie znajdują się stymulatory oparte na kolagenie, które efektywnie odbudowują strukturę skórną, oraz te oparte na kwasach, które poprawiają jędrność i gęstość skóry. Każdy z tych produktów jest aplikowany przez doświadczonych specjalistów, co gwarantuje bezpieczeństwo i wysoką efektywność zabiegów.',
          },
          {
            type: 'paragraph',
            content:
              'Nasze podejście pozwala na maksymalizację efektów każdego zabiegu, przynosząc znaczące i długotrwałe wyniki w poprawie jakości i kondycji skóry pacjenta.',
          },
        ],
      },
      {
        type: 'section',
        subtitle: 'Efekty i Regeneracja Skóry',
        nodes: [
          {
            type: 'paragraph',
            content:
              'Stosowanie stymulatorów tkankowych w naszej klinice przynosi widoczne i długotrwałe efekty. Pacjenci mogą zauważyć znaczącą poprawę jędrności, elastyczności oraz ogólnego stanu skóry. Efekty te są wynikiem intensywnej stymulacji komórek skóry do <b>odbudowy i regeneracji</b>. Regularne zabiegi pozwalają na utrzymanie młodzieńczego wyglądu skóry, zmniejszenie widoczności zmarszczek i linii mimicznych, a także znaczną poprawę nawilżenia. Nasze zabiegi zapewniają odbudowę kolagenu, co prowadzi do długotrwałej poprawy kondycji skóry.',
          },
        ],
      },
      {
        type: 'bannerSection',
      },
      {
        type: 'section',
        subtitle: 'Zabiegi Stymulatorami Tkankowymi Tylko w IDEALE',
        nodes: [
          {
            type: 'paragraph',
            content:
              '<b>IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę dla kobiet</b>. Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące. Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi z wykorzystanie stymulatorów tkankowych, doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc parkingowych.',
          },
          {
            type: 'paragraph',
            content: `W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych <a href="${PATHS.OFFER}" >zabiegów medycyny estetycznej</a>, które czerpiemy prosto z międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia najwyższej jakości usług, a nasz zespół stale poszerza swoją wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom. Cena wybranego zabiegu jest dostępna w naszym cenniku.`,
          },
          {
            type: 'paragraph',
            content: `Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość. Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą skórą.`,
          },
        ],
      },
      {
        type: 'faq',
        subtitle: '',
        nodes: [
          {
            question: 'Jak działa zabieg stymulatorami tkankowymi w medycynie estetycznej?',
            answer: 'Stymulator tkankowy to zaawansowany preparat, który pobudza komórki skóry do produkcji kolagenu i elastyny. To kluczowe białka odpowiedzialne za jędrność i elastyczność skóry. Dzięki temu  zabieg biostymulatorami  tkankowymi skutecznie przyczyniają się do regeneracji skóry, następuję odbudowa kolagenu skóry oraz powodują redukuje oznak starzenia się i poprawiają ogólną jakość cery.',
          },
          {
            question: 'Karisma czy Profhilo – który stymulator tkankowy jest lepszy? ',
            answer: 'Zarówno Karisma (mezoterapia kolagenowa) jak i Profhilo to popularne preparaty uzywane do  stymulacji skóry , które mają za zadanie poprawić jej kondycję przez intensywną regenerację i  nawilżenie tkankowe . Wybór między nimi zależy od indywidualnych potrzeb pacjenta i specyfiki skóry. Profhilo jest znane z bardzo silnego nawilżenia i bioremodelingu skóry, podczas gdy Karisma może oferować bardziej zróżnicowane efekty w zależności od stosowanej formuły.',
          },
          {
            question: 'Jakie efekty można oczekiwać po zabiegu z użyciem stymulatorów tkankowych? ',
            answer: 'Zabiegi te znacząco poprawiają jędrność i elastyczność skóry, co przekłada się na młodszy wygląd. Pacjenci często zauważają mniejszą widoczność zmarszczek, głębsze nawilżenie oraz poprawę ogólnej tekstury skóry. Efekty są długotrwałe i mogą być widoczne już po pierwszej sesji.',
          },
          {
            question: 'Czy stosowanie stymulatorów tkankowych jest bezpieczne?',
            answer: 'Tak, zabiegi te są bezpieczne, gdy są wykonywane przez doświadczonego specjalistę w medycynie estetycznej. W IDEALE stosujemy tylko sprawdzone i bezpieczne preparaty, a każdy zabieg jest dostosowany do indywidualnych potrzeb i cech skóry pacjenta, co minimalizuje ryzyko działań niepożądanych.',
          },
          {
            question: 'Jak często powinno się stosować zabiegi z użyciem stymulatorów tkankowych dla najlepszych efektów? ',
            answer: 'Częstotliwość zabiegów zależy od indywidualnych potrzeb skóry oraz rodzaju użytego stymulatora. Zazwyczaj zaleca się serię zabiegów (od 3 do 5), przeprowadzanych w odstępach 4-6 tygodni, aby maksymalizować efekty regeneracji i odmłodzenia skóry. Regularne sesje pomagają utrzymać i przedłużyć pozytywne rezultaty.',
          },
        ],
      },
    ],
  };
};
