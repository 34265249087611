import React from 'react';
import { TreatmentsSubpage } from 'views/treatments-subpage';
import { useStimulantsContent } from 'views/treatments-subpage/hooks/stimulants';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { treatmentsHubs } from 'constants/treatment-hubs';
import { Layout } from 'layouts';

const TissueStimulatorsPage = () => {
  const stimulantsContent = useStimulantsContent();

  return (
    <Layout>
      <TreatmentsSubpage
        breadcrumbLinks={treatmentsHubs.tissueStimulator.links}
        {...stimulantsContent}
      />
    </Layout>
  );
};

export default TissueStimulatorsPage;

export const Head = () => <SEO tags={TAGS.zabiegiStymulatory} />;
